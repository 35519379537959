





















































































import Vue from 'vue';
import { mapGetters } from 'vuex';
import { ApiResource } from '@/types';

export default Vue.extend({
  computed: {
    ...mapGetters({
      findByVanity: 'profiles/FindByVanity',
    }),
    profile(): ApiResource.Profile {
      return this.findByVanity(this.$route.params.VANITY);
    },
  },
});
