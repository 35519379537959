




























































































































import Vue from 'vue';
import { mapGetters } from 'vuex';
import { ApiResource } from '@/types';

export default Vue.extend({
  components: {
    WatchlistButton: () => import(/* webpackChunkName: "v-p-wlb" */ '@/components/profile/WatchlistButton.vue'),
  },
  data() {
    return {
      notFound: false,
    };
  },
  computed: {
    ...mapGetters({
      detect: 'business/Detect',
      findByVanity: 'profiles/FindByVanity',
      listPages: 'profiles/pages/List',
    }),
    profile(): ApiResource.Profile | undefined {
      if (this.$route.params.VANITY) return this.findByVanity(this.$route.params.VANITY);
      return undefined;
    },
    pages(): ApiResource.Profile.Page[] {
      if (!this.profile) return [];
      return this.listPages(this.profile.id)
        .sort((a: ApiResource.Profile.Page, b: ApiResource.Profile.Page) => a.weight - b.weight);
    },
  },
  watch: {
    '$route.params.VANITY': {
      immediate: true,
      handler(to) {
        if (to) this.fetch();
      },
    },
  },
  methods: {
    async fetch() {
      const data = await this.$store.dispatch('profiles/FindByVanity', this.$route.params.VANITY)
        .catch(() => {
          this.notFound = true;
        });

      const actualVanity = data?.data?.vanity;

      if (!this.notFound && actualVanity !== this.$route.params.VANITY) {
        this.$router.replace({
          params: { VANITY: actualVanity },
          query: this.$route.query,
        });
      }
    },
  },
  meta() {
    const { name, vanity } = this.profile || {} as any;

    return {
      titleTemplate(chunk: string) {
        let title = 'Auftrag.app';
        if (name && vanity) title = `${name} (@${vanity}) / ${title}`;
        if (chunk) title = `${chunk} / ${title}`;
        return title;
      },
    };
  },
});
